<template>
  <div>
    <h1 class="headline">{{ date.format('DD. MMMM') }}</h1>
    <main class="page-content" v-if="isAllowedToView">
      <slot name="page-content" class="page-content"></slot>
    </main>
    <main v-else>
      <div class="page-content">
        <h2>{{ randomQuote }}</h2>
        <img
          width="300"
          :src="stop"
          alt=""
        >
      </div>
    </main>
    <slot name="back">
      <back-to-calendar :year="year"></back-to-calendar>
    </slot>
  </div>
</template>

<script>
import Moment from 'moment'
import BackToCalendar from "@/components/BackToCalendar";

export default {
  name: 'Day',
  props: ['day'],
  components: {
    BackToCalendar
  },
  data () {
    return {
      stop: require('../assets/cat-stop.gif'),
      quotes: [
        'Nicht cheaten!',
        'Falscher Tag',
        'Gedulds-Bebi gefragt',
        'Bebi says no'
      ]
    }
  },
  computed: {
    date: function () {
      return new Moment(this.day, 'DD.MM.YYYY').lang("de")
    },
    now: function () {
      return new Moment()
    },
    isAllowedToView: function () {
      // return true
      return this.date <= this.now
    },
    year: function () {
      return this.date.format('YYYY')
    },
    randomQuote () {
      console.log(this.quotes[Math.floor(Math.random() * 4)])
      return this.quotes[Math.floor(Math.random() * 4)]
    }
  }
}
</script>

<style lang="scss">
h1 {
  margin-bottom: 30px;
}
.page-content {
  width: 50%;
  margin: 0 auto;
  flex-flow: column;
  text-align: center;

  background: rgba(#000, 0.3);
  padding: 25px;

  @media screen and (max-width: 900px) {
    width: 75%;
  }

  @media screen and (max-width: 400px) {
    width: 90%;
  }
}

.page-content video {
  max-width: 600px;
}

.small-video {
  display: flex;
  justify-content: center;
}

img.thumbnail {
  max-height: 100px;
  width: auto;
}

.big {
  max-width: 600px;
  width: 100%;
}

p.center {
  text-align: center;
}
</style>
