<template>
  <Day day="14.12.2021">
    <div slot="page-content">
      Bereit für die nächste (Geschenk)-Party?<br>
      PS: VIel Glück bei der Prüfung!
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day14',
  components: {
    Day
  }
}
</script>
