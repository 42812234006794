<template>
  <Day day="20.12.2021">
    <div slot="page-content">
      <h2 style="font-size: 40px; margin-bottom:0">Bebis secret</h2>
      <span style="font-size: 14px">(Teil 1)</span>
      <p style="text-decoration: underline;">Pxbe wn wtl Uxlmx bg fxbgxf Exuxg ublm</p>

      <p style="font-size: 12px">Was ist das? Ein geheimer Code? Eventuell irgendwann noch nützlich</p>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day20',
  components: {
    Day
  }
}
</script>
