<template>
  <Day day="17.12.2021">
    <div slot="page-content">
      GUTSCHEIN: Eine Aktivität deiner Wahl
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day17',
  components: {
    Day
  }
}
</script>
