<template>
  <Day day="01.12.2021">
    <div slot="page-content">
      <h3>Am höchsten Punkt der tiefsten Ebene findest du mich</h3>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day01',
  components: {
    Day
  }
}
</script>
