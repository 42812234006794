<template>
  <Day day="24.12.2021">
    <div slot="page-content">
      <p>🎄🎄 Es ist soweit 🎄🎄</p>
      <p>Heute ist dein Geschenk natürlich dort wo es hingehört</p>
      <p>Ich liebe dich</p>
      <p style="font-size:80px">♥️</p>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day24',
  components: {
    Day
  }
}
</script>
