<template>
  <div class="container">
    <h1>Bebi's Adventskalender<br>2021</h1>
    <div class="cards-wrapper">
      <ul class="cards">
        <li
          class="card"
          v-for="i in range(1,24)"
          :key="i"
        >
          <div>
            <router-link :to="'/day/' + i">
              {{ i }}
            </router-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Calendar',
  methods: {
    range: function (min, max) {
      let arr = []
      let j = 0
      for (let i = min; i <= max; i++) {
        arr[j++] = i
      }

      return arr
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 3rem;
}
.cards-wrapper {
  margin: 0 auto;
  max-width: 100%;
  display: flex;
  justify-content: center;
  
}

h1 {
  margin-top: 2rem;
}

.cards {
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; 
  padding-left: 0;

  @media screen and (max-width: 385px) {
    grid-template-columns: 1fr 1fr;
  }
}

.card {
  width: 100px;
  height: 100px;

  border-radius: 25px;

  margin: 35px;

  background: rgba(#000, 0.1);
  border: 1px solid #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.5s;

  &:hover {
    cursor: pointer;
    background: rgba(#000, 0.3);
    box-shadow: 0 0 20px rgba(#fff ,0.3);
  }

  a {
    outline: 0;
    color: #fff;
    text-decoration: none;

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:active,
    &:focus {
      border: none;
    }
  }

  div {
    font-family: 'Handlee', cursive;
    font-size: 52px;
    font-weight: bold;
    padding-top: 5px;

    width: 100%;
    height: 100%;
  }
}
</style>
