<template>
  <Day day="03.12.2021">
    <div slot="page-content">
      <h3>It's tea time</h3>
      <img
          width="300"
          src="https://placekitten.com/300/300"
          alt=""
      >
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day03',
  components: {
    Day
  }
}
</script>
