<template>
  <Day day="02.12.2021">
    <div slot="page-content">
      <h3>Euphorbia pulcherrima - a star is born</h3>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day02',
  components: {
    Day
  }
}
</script>
