<template>
  <Day day="08.12.2021">
    <div slot="page-content">
      Weil ich weiß wie gern du sie hast
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day08',
  components: {
    Day
  }
}
</script>
