<template>
  <Day day="05.12.2021">
    <div slot="page-content">
      <h4>Weil es die letzten schon so gut war</h4>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day05',
  components: {
    Day
  }
}
</script>
