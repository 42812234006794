<template>
  <Day day="23.12.2021">
    <div slot="page-content">
      <p>Weil morgen Weihnachten ist gibt es heute schon einen Christbaum:</p>
      <h3 style="font-size:140px"><a href="https://www.dogbible.com/media/dev/gutschein.pdf" target="_blank" style="text-decoration: none;">🎄</a></h3>
      <p><small>PS: Clicke auf den Baum</small></p>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day23',
  components: {
    Day
  }
}
</script>
