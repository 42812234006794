<template>
  <Day day="15.12.2021">
    <div slot="page-content">
      Und er trank noch einen Schluck [Tee einfügen]
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day15',
  components: {
    Day
  }
}
</script>
